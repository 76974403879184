
/* Translation Widget */
#google_translate_element {
  background: #fff;
  padding: 7px;
  margin-left: 15px;
}

/* RESET */
body {
  background-color: #FFF;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
h1, h2, h3, h4, h5, h6 {
  line-height: initial;
  margin-block-start: 0;
  margin-block-end: 0;
}
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
svg {
  clip-rule: evenodd;
  display: block;
  fill-rule: evenodd;
}
input,
textarea,
select {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: #333;
  display: block;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  padding: 0;
  width: 100%;
}
button {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  padding: 0;
}

/* OVERRIDES TO REVIST */
.article {
  padding: 0 !important;
  margin-block-end: 0 !important;
}

/* ELEVIO MAIN SETTINGS OVERRIDES */
a.link-color {
  color: #253675 !important;
}
a.link-color:hover {
  color: #253675 !important;
}
.link-color svg {
  fill: #0075ff;
}
.link-color:hover svg {
  fill: #0075ff;
}
.show-more {
  background-color: #0075ff;
}
a.article-count{
  color: #0d45d5;
}
a.article-count:hover{
  color: #0d45d5;
}
.header-text-color {
    color: #ffffff;
  }
.header-bg-color {
  background-color: #206693 !important;
}
.search-bg-color {
  background-color: #ffffff;
}
.bg-color {
  background-color: #ffffff;
}
.page-text-color {
  color: #000000;
}
.logged-in .login-link,
.logged-out .logout-link {
  display: none
}

.submit {
  background-color: #EEE;
  color: #333;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1120px;
  padding-block-start: 8px;
  padding-block-end: 8px;
  width: 80%;
}
.nav-details {
  align-items: center;
  display: flex;
}
.nav-logo {
  display: block;
}
.nav-logo-image {
  height: 24px;
  width: 56px;
}
.nav-title {
  margin-inline-start: 16px;
}
.nav-desktop-menu {
  display: none;
}
.nav-desktop-menu-link + .nav-desktop-menu-link {
  margin-inline-start: 16px;
}
a.nav-desktop-menu-link {
  color: #fff;
  text-decoration: none;
}
.nav-mobile-menu {
  display: flex;
}
.nav-mobile-menu-trigger,
.nav-mobile-search-trigger {
  fill: #666;
  padding: 2px;
  margin-inline-start: 8px;
}
.nav-mobile-menu-trigger:hover,
.nav-mobile-search-trigger:hover {
  fill: #333;
}
.nav-mobile-menu-trigger:active,
.nav-mobile-search-trigger:active {
  fill: #000;
}
@media screen and (min-width: 768px) {
  .nav-desktop-menu {
    align-items: center;
    display: flex;
  }
  .mobile-menu {
    display: none;
  }
}

.mobile-menu {
  padding-bottom: 8px;
}

/* LANGUAGE PICKER */
.language-picker {
  color: #333;
  fill: #666;
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 32px;
  position: relative;
}
.language-picker-input {
  background-color: #FFF;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  padding: 8px;
  position: relative;
}
.language-picker-input::before {
  border: thin solid #CCC;
  border-radius: inherit;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}
.language-picker-image {
  border-radius: 3px;
  margin-inline-end: 4px;
  width: 20px;
}
.language-picker-name {
  flex-grow: 1;
  padding-inline-start: 4px;
  padding-inline-end: 4px;
}
.language-picker-icon {
  flex-shrink: 0;
  height: 16px;
  margin-inline-start: 4px;
  padding: 2px;
  width: 16px;
}
.language-picker-item .language-picker-icon {
  position: absolute;
  right: 16px;
  top: 8px;
}
.language-picker-overlay {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.language-picker-list {
  -webkit-overflow-scrolling: touch;
  background-color: #FFF;
  border: thin solid #CCC;
  border-radius: 6px;
  box-shadow: 0 4px 16px rgba(0,0,0,0.1);
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  max-height: calc(200px);
  overflow: auto;
  position: absolute;
  padding-block-start: 8px;
  padding-block-end: 8px;
  padding-inline-start: 0;
  right: 0;
  top: calc(100% + 4px);
  white-space: nowrap;
  z-index: 2;
}
.language-picker-item {
  align-items: center;
  color: #333;
  cursor: pointer;
  display: flex;
  fill: #666;
  padding: 8px 52px 8px 16px;
  position: relative;
}
.language-picker-item:hover {
  background-color: #F7F7F7;
}
@media screen and (min-width: 1024px) {
  .language-picker {
    margin-block-start: 0;
    margin-inline-start: 16px;
  }
}

/* HERO */
.hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.hero-wrapper {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1120px;
  padding-block-start: 32px;
  padding-block-end: 64px;
  text-align: center;
  width: 80%;
}
.hero-wrapper h1 {
  color: #FFF;
}
.hero-search {
  color: black;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.16666 3.33341C5.945 3.33341 3.33332 5.94509 3.33332 9.16675C3.33332 12.3884 5.945 15.0001 9.16666 15.0001C10.7777 15.0001 12.2349 14.3481 13.2914 13.2915C14.348 12.235 15 10.7778 15 9.16675C15 5.94509 12.3883 3.33341 9.16666 3.33341ZM1.66666 9.16675C1.66666 5.02461 5.02452 1.66675 9.16666 1.66675C13.3088 1.66675 16.6667 5.02461 16.6667 9.16675C16.6667 10.9373 16.0524 12.5654 15.0265 13.8481L18.0892 16.9108C18.4147 17.2363 18.4147 17.7639 18.0892 18.0893C17.7638 18.4148 17.2362 18.4148 16.9107 18.0893L13.848 15.0266C12.5653 16.0525 10.9372 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3089 1.66666 9.16675Z' fill='%2374808A'/%3E%3C/svg%3E%0A");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: thin solid #CCC;
  background: white;
  border-radius: 6px;
  display: inline-block;
  padding: 16px 24px 16px 44px;
  margin-block-start: 32px;
  max-width: 800px;
}
.search-bar {
  color: #000000 !important;
}

/* MOBILE MENU */
.mobile-menu-wrapper {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1120px;
}
.mobile-menu-link {
  display: block;
  text-align: center;
  align-self: center;
  padding-left: 10px;
}
.mobile-menu-link + .mobile-menu-link {
  margin-block-start: 32px;
}
@media screen and (min-width: 1024px) {
  .mobile-menu {
    display: none;
  }
}

/* SEARCH */
.mobile-search-wrapper {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1120px;
  padding-block-end: 32px;
  width: 80%;
}
.mobile-search-input {
  background-color: #FFF;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.16666 3.33341C5.945 3.33341 3.33332 5.94509 3.33332 9.16675C3.33332 12.3884 5.945 15.0001 9.16666 15.0001C10.7777 15.0001 12.2349 14.3481 13.2914 13.2915C14.348 12.235 15 10.7778 15 9.16675C15 5.94509 12.3883 3.33341 9.16666 3.33341ZM1.66666 9.16675C1.66666 5.02461 5.02452 1.66675 9.16666 1.66675C13.3088 1.66675 16.6667 5.02461 16.6667 9.16675C16.6667 10.9373 16.0524 12.5654 15.0265 13.8481L18.0892 16.9108C18.4147 17.2363 18.4147 17.7639 18.0892 18.0893C17.7638 18.4148 17.2362 18.4148 16.9107 18.0893L13.848 15.0266C12.5653 16.0525 10.9372 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3089 1.66666 9.16675Z' fill='%2374808A'/%3E%3C/svg%3E%0A");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border: thin solid #CCC;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 16px 12px 40px;
}
@media screen and (min-width: 1024px) {
  .mobile-search {
    display: none;
  }
}

/* SEARCH - EMPTY */
.search-empty {
  text-align: center;
}
.search-empty-title {
  font-size: 32px;
  line-height: 48px;
}
.search-empty-message {
  font-size: 18px;
  line-height: 28px;
  margin-block-start: 16px;
}

/* BODY */
.body-wrapper {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1120px;
  width: 80%;
  margin-top: 10px;
  padding-bottom: 80px;
}

.logo-wrapper {
  text-align: center;
  margin: 20px 0px 40px 0px;
  border-bottom: 0.5px #ece7e6 solid;
}

.logo-center {
  height: 81px;
}

/* GRID */
.grid {
  column-gap: 32px;
  display: grid;
  row-gap: 32px;
}
.grid-item {
  display: grid;
}
@media screen and (min-width: 720px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* CARD */
.card {
  background-color: #F7F7F7;
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px 32px;
  text-align: center;
  text-decoration: none;
}
.card-icon {
  height: 48px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-block-end: 32px;
  width: 48px;
}
.card-title {
  color: #000;
}
.card-divider {
  background-color: #CCC;
  height: 1px;
  margin: 32px auto 16px auto;
  width: 64px;
}
.card-info {
  color: #666;
  margin-block-start: 16px;
}

/* FOOTER */
.footer {
  position: absolute;
  color: #ffffff;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  background: #206693;
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-block-start: 8px;
  padding-block-end: 8px;
  width: 90%;
}
.footer-nav {
  display: flex;
}
.footer-link {
  color: #ffffff;
  display: block;
  text-decoration: none;
}
.footer-link + .footer-link {
  margin-left: 16px;
}

/* WITH ASIDE */
.main {
  margin-block-end: 32px;
}
.aside {
  display: grid;
  row-gap: 32px;
}

@media screen and (min-width: 720px) {
  .main-centered {
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: 66.666666%;
  }
}
@media screen and (min-width: 1024px) {
  .with-aside {
    align-items: start;
    column-gap: 32px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 20px;
  }
  .aside {
    grid-column-start: 9;
    grid-column-end: 13;
    grid-row-start: 1;
    padding-inline-start: 32px;
  }
  .main {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    margin-block-end: 0;
  }
}

/* MAIN */
.main-header {
  margin-block-end: 32px;
}
.main-content-card {
  position: relative;
}
.header-text {
  font-size: 2em;
}
@media screen and (min-width: 1024px) {
  .main-content-card {
    border-radius: 8px;
  }
}
@media screen and (max-width: 720px) {
  .aside {
    margin-bottom: 100px;
  }
  .show-more {
    margin-bottom: 100px;
  }
}

/* ARTICLE ACTIONS */
.article-actions {
  display: flex;
  margin-block-start: -16px;
  margin-block-end: 16px;
}
.article-actions-button {
  fill: #666;
  padding: 8px;
  position: relative;
}
.article-actions-button:hover {
  fill: #333;
}
.article-actions-icon {
  height: 16px;
  padding: 2px;
  width: 16px;
}
.article-actions-tooltip {
  background-color: #333;
  border-radius: 4px;
  bottom: 100%;
  color: #FFF;
  font-size: 14px;
  left: 50%;
  padding: 4px 12px;
  position: absolute;
  transform: translate3d(-50%, 0, 0);
}
.article-actions-tooltip::before {
  border-color: #333 transparent transparent transparent;
  border-style: solid;
  border-width: 4px;
  content: '';
  display: block;
  left: calc(50% - 4px);
  position: absolute;
  top: 100%;
}
@media screen and (min-width: 1024px) {
  .article-actions {
    margin-block-start: 0;
    margin-block-end: 0;
    position: absolute;
    top: 35px;
    right: 16px;
  }
}

/* SIDEBAR SEARCH */
.sidebar-search {
  background-color: #FFF;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.16666 3.33341C5.945 3.33341 3.33332 5.94509 3.33332 9.16675C3.33332 12.3884 5.945 15.0001 9.16666 15.0001C10.7777 15.0001 12.2349 14.3481 13.2914 13.2915C14.348 12.235 15 10.7778 15 9.16675C15 5.94509 12.3883 3.33341 9.16666 3.33341ZM1.66666 9.16675C1.66666 5.02461 5.02452 1.66675 9.16666 1.66675C13.3088 1.66675 16.6667 5.02461 16.6667 9.16675C16.6667 10.9373 16.0524 12.5654 15.0265 13.8481L18.0892 16.9108C18.4147 17.2363 18.4147 17.7639 18.0892 18.0893C17.7638 18.4148 17.2362 18.4148 16.9107 18.0893L13.848 15.0266C12.5653 16.0525 10.9372 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3089 1.66666 9.16675Z' fill='%2374808A'/%3E%3C/svg%3E%0A");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border: thin solid #CCC;
  border-radius: 6px;
  display: none;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 16px 12px 40px;
}
@media screen and (min-width: 1024px) {
  .sidebar-search {
    display: block;
  }
}

/* BREADCRUMBS */
.breadcrumbs {
  display: flex;
  font-size: 14px;
  line-height: 20px;
}
.breadcrumbs-link {
  text-decoration: none;
}
.breadcrumbs-link:hover {
  text-decoration: underline;
}
.breadcrumbs-divider {
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}
.breadcrumbs-divider-icon path {
  color: #2d81c6 !important;
}

.breadcrumbs-header-wrapper {
  background-color: #f2f2f2;
  border-bottom: 1px solid #e6e6e6;
}

.breadcrumbs-header {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1120px;
  width: 80%;
  padding: 5px;
}

/* ARTICLE LINK */
.article-title {
  color: #253675;
}

.article-link {
  background-color: #F7F7F7;
  color: inherit;
  display: flex;
  outline: none;
  padding: 24px 32px;
  text-decoration: none;
}
.article-link + .article-link {
  margin-block-start: 32px;
}
.article-link:hover {
  background-color: #F7F7F7;
}
.article-link-breadcrumbs {
  margin-block-end: 8px;
}
.article-link-image {
  flex-shrink: 0;
  height: 80px;
  width: auto;
}
.article-link-summary {
  color: #333;
  margin-block-start: 8px;
}

/* ARTICLE META */
.article-meta {
  color: #333;
  fill: #333;
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 16px;
}
.article-meta-item {
  display: flex;
}
.article-meta-item + .article-meta-item {
  margin-block-start: 8px;
}
.article-meta-icon {
  height: 12px;
  padding: 4px;
  width: 12px;
}
.article-meta-label {
  margin-inline-start: 4px;
}

/* ARTICLE/CATEGORY LIST */
.article-list,
.category-list {
  font-size: 16px;
  line-height: 20px;
  list-style: none;
  margin-block-start: 16px;
  margin-block-end: 0;
  padding-inline-start: 0;
}
.article-list-item,
.category-list-item {
  margin-block-start: 8px;
}
.article-list-link,
.category-list-link {
  text-decoration: none;
  color: #333;
}
.article-list-link:hover,
.category-list-link:hover {
  color: #000;
  text-decoration: underline;
}

/* SHOW MORE */
.show-more {
  background-color: #F7F7F7;
  font-weight: 500;
  margin-block-start: 32px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding: 16px 20px;
}
.show-more:disabled {
  opacity: .5;
}

/* ERROR */
.error {
  text-align: center;
}
.error-title {
  font-size: 56px;
  line-height: 64px;
}
.error-message {
  font-size: 18px;
  line-height: 28px;
  margin-block-start: 24px;
}
.error-search {
  background-color: #FFF;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.16666 3.33341C5.945 3.33341 3.33332 5.94509 3.33332 9.16675C3.33332 12.3884 5.945 15.0001 9.16666 15.0001C10.7777 15.0001 12.2349 14.3481 13.2914 13.2915C14.348 12.235 15 10.7778 15 9.16675C15 5.94509 12.3883 3.33341 9.16666 3.33341ZM1.66666 9.16675C1.66666 5.02461 5.02452 1.66675 9.16666 1.66675C13.3088 1.66675 16.6667 5.02461 16.6667 9.16675C16.6667 10.9373 16.0524 12.5654 15.0265 13.8481L18.0892 16.9108C18.4147 17.2363 18.4147 17.7639 18.0892 18.0893C17.7638 18.4148 17.2362 18.4148 16.9107 18.0893L13.848 15.0266C12.5653 16.0525 10.9372 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3089 1.66666 9.16675Z' fill='%2374808A'/%3E%3C/svg%3E%0A");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: thin solid #CCC;
  border-radius: 6px;
  display: inline-block;
  padding: 16px 24px 16px 44px;
  margin-block-start: 24px;
  max-width: 400px;
}

/* ACCESS BANNER */
.access-banner {
  border: thin solid #CCC;
  border-radius: 6px;
  margin-block-start: 32px;
  overflow: hidden;
}
.access-banner-header {
  background-color: #F7F7F7;
  display: flex;
  padding: 16px;
}
.access-banner-icon {
  flex-shrink: 0;
  height: 16px;
  padding: 4px;
  width: 16px;
}
.access-banner-title {
  flex-grow: 1;
  font-weight: 500;
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}
.access-banner-toggle {
  flex-shrink: 0;
}
.access-banner-body {
  border-top: thin solid #CCC;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 16px 24px;
}
.access-banner-group + .access-banner-group {
  margin-block-start: 4px;
}

/* ALERT */
.alert {
  animation-name: alert;
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  background-color: #333;
  border-radius: 6px;
  bottom: 0;
  color: #FFF;
  display: flex;
  fill: #FFF;
  font-size: 14px;
  left: 50%;
  line-height: 20px;
  padding: 16px;
  position: fixed;
  transform: translate3d(-50%, -32px, 0);
  z-index: 1;
}
.alert-error {
  background-color: #600;
}
.alert-success {
  background-color: #060;
}
.alert-message {
  padding-inline-start: 4px;
  padding-inline-end: 4px;
}
.alert-close {
  flex-shrink: 0;
  margin-inline-start: 4px;
}
.alert-icon {
  height: 16px;
  padding: 2px;
  width: 16px;
}

@keyframes alert {
  0% {
    opacity: 0;
    transform: translate3d(-50%, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(-50%, -32px, 0);
  }
}

/* ARTICLE FEEDBACK */
.article-feedback {
  border-block-start: thin solid #EEE;
  margin-block-start: 32px;
  margin-inline-start: -32px;
  margin-inline-end: -32px;
  padding-block-start: 32px;
  padding-inline-start: 32px;
  padding-inline-end: 32px;
  text-align: center;
}
.article-feedback-title {
  margin-bottom: 32px;
  font-size: 1.17em;
}
.article-feedback-options {
  display: flex;
  justify-content: center;
  margin-block-start: 16px;
}
.article-feedback-options {
  display: flex;
  justify-content: center;
  margin-block-start: 16px;
}
.article-feedback-options .reaction {
  border: 2px solid #c1d7de;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 0;
  margin: 0 4px;
  opacity: .5;
  padding: 15px;
  transition: all .25s ease-out;
  color: #ffffff;
}
.article-feedback-options .reaction:hover {
  opacity: 1;
}
.article-feedback-options .reaction.down {
  border-color: #da4429;
}
.article-feedback-options .reaction.up {
  border-color: #15cd72;
}
.article-feedback-options .reaction.down svg {
  fill: #da4429;
  transform: rotate(180deg);
}
.article-feedback-options .reaction.up svg {
  fill: #15cd72;
}
.article-feedback-options .reaction:hover.down {
  background-color: #da4429;
}
.article-feedback-options .reaction:hover.up {
  background-color: #15cd72;
}
.article-feedback-options .reaction:hover.down svg,
.article-feedback-options .reaction:hover.up svg {
  fill: #fff;
}
.article-feedback-icon {
  height: 30px;
  width: 30px;
}
.article-feedback-button.secondary {
  background-color: #EEE;
  color: #333;
}
.article-feedback-button + .article-feedback-button {
  margin-inline-start: 8px;
}
.article-feedback-input {
  border: thin solid #CCC;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 16px;
  max-width: 100%;
  min-width: 100%;
  padding: 8px 12px;
}
.article-feedback-input:focus {
  border-color: #666;
}
.article-feedback-input.invalid {
  background-color: #FEE;
  border-color: #600;
  color: #300;
}
.article-feedback-actions {
  display: flex;
  margin-block-start: 32px;
}
.article-feedback-message {
  color: #900;
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 8px;
  text-align: start;
}

/* PAGE LOADER */
.page-loader {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* LOADING BAR */
.loading-bar {
  background-color: blue;
  box-shadow: rgb(0, 102, 255) 0px 1px 5px 2px;
  position: fixed;
  height: 2px;
  top: 0;
  transition-property: opacity, transform, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  width: 100%;
  will-change: opacity, transform, visibility;
}

.loading-bar.hidden {
  opacity: 0;
  visibility: hidden;
}

.request-link {
  display: flex;
}

.request-link-logo {
  height: 35px;
  width: 35px;
  margin-right: 5px;
}

.request-link-text {
  align-self: center;
}

.request-submission-container {
  position: relative;
}

.mobile-menu-wrapper .request-submission-container {
  position: relative;
  display: flex;
  flex-direction: row;
}

.request-field-dropdown {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 420px;
  max-width: 500px;
  padding: 23px;
  background: white;
  position: absolute;
  box-shadow: 5px 4px 10px 0 rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  right: 0;
  z-index: 1;
}

.request-field-dropdown * {
  color: #000;
}

@media screen and (max-width: 900px) {
  .request-field-dropdown {
    min-width: unset;
    width: 280px;
    left: -10px;
    font-size: 12px;
  }

  .error-message {
    font-size: 10px;
  }

  .request-link-text {
    font-size: 14px;
  }

  .request-link-logo {
    height: 30px;
    width: 30px;
  }
}

.request-input {
  width: 100%;
  margin: 5px 0px;
}

.request-action {
  width: 100%;
  margin-top: 15px;
}

.request-action button {
  background-color: #206693;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  color: #fff !important;
}


.request-action button:disabled {
  /* background-color: rgb(128, 159, 183); */
  opacity: 0.5;
}

.request-input-type-toggle-section {
  display: flex;
  width: 100%;
  margin: 7px 0px;
}

.request-input-type-toggle-button {
  width: 50%;
  border: solid #80808085;
  border-width: thin;
  border-radius: 5px;
  border-collapse: collapse;
  height: 50px;
  color: rgb(152, 151, 149) !important;
  font-size: 17px;
}

.request-field-dropdown-text {
  color: rgb(152, 151, 149) !important;
  font-weight: 300;
  font-size: 17px;
  margin-bottom: 3px;
}


.request-input-type-toggle-button.selected {
  /* background-color: #004070; */
  background-color: #206693;
  color: white !important;
}

.request-input-type-toggle-button + .request-input-type-toggle-button {
  border-top-left-radius:0;
  border-bottom-left-radius:0;
}

.request-input-type-toggle-button:first-of-type {
  border-top-right-radius:0;
  border-bottom-right-radius:0;
}

.request-field-hr {
  margin-top: 13px;
  width: 100%;
  border: none;
  background-color: #80808085;
  height: 1px;
}

.request-input input {
  border: solid #80808085;
  border-width: thin;
  border-radius: 5px;
  padding-left: 20px;
  height: 50px;
  color: #000 !important;
  font-size: 17px;
}

.request-input div {
  font-size: 12px;
  color: red;
  line-height: 12px;
  padding-top: 5px;
}

.response-message-container {
  padding-right: 20px;
}

.response-message {
  position: relative;
}

.response-message span {
  position: absolute;
  font-size: 13px;
  right: -12px;
  top: -12px;
  cursor: pointer;
  color: #000 !important;
}

.response-message div {
  font-size: 14px;
  color: #000 !important;
}

.submit-request-link {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 50px;
  margin-bottom: 15px;
  right: 50px;
}

.submit-request-link > a > span {
  position: absolute;
  width: 115px;
  left: -10;
  right: 0;
  bottom: 0;
  color: #253675
}

.submit-request-link.hide-submit-request {
  display: none;
}

.submit-request {
  background-color: #145DA0;
  padding: 11px 19px;
}

.request-page {
  display: block;
  position: relative;
}
.request-page > iframe {
  width: 100%;
  height: 100vh;
  border: none;
}

iframe .container {
  width: max-content;
}

a.goog-te-menu-value span:last-child {
  display: none !important;
}
.goog-te-gadget-simple img:nth-child(1) {
  margin-left: 12px;
}

a {
  text-decoration: none !important;
  background-image: none !important;
}

.body a:hover {
  text-decoration: underline !important;
}

.crm-submission__navigation-bar {
  padding: 15px;
}

main.hide-submit-request{
  background-color: #efeeee;
}

.submit-request-link-ios {
  position: fixed;
  bottom: 40px;
  margin-bottom: 10px;
  margin-left: 43%;
}

.submit-request-link-ios > a > span {
  position: absolute;
  width: 115px;
  left: -10;
  right: 0;
  bottom: 0;
  color: #253675
}

.submit-request-link-ios.hide-submit-request {
  display: none;
}

.submit-request-ios {
  background-color: #145DA0;
  padding: 11px 19px;
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .submit-request-link {
    position: sticky;
    margin-bottom: 10px;
    display: -webkit-flex;
    -webkit-justify-content: flex-end;
    display: flex;
    justify-content: flex-end;
  }

  .submit-request {
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 15px;
    cursor: pointer;
    margin-right: 15px;
  }

  .footer-wrapper {
    font-size: 14px;
  }

  .footer {
    position: inherit !important;
  }
}

@media only screen and (min-width: 360px)  and (max-width: 900px) {
  .submit-request {
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 15px;
    cursor: pointer;
  }
}


@media screen and (min-width: 768px) {
/* Translation Widget */
  #google_translate_element {
    background: #fff;
    padding: 1px;
    margin-left: 15px;
    border-radius: 4px;
  }
  .goog-te-gadget-simple .goog-te-menu-value {
    color: rgb(255, 255, 255) !important;
  }

  .goog-te-gadget-simple {
    background-color: #206693 !important;
    color: white !important;
    border-radius: 4px;
    border: 0px solid #d5d5d5 !important;
    font-size: 10pt;
    display: inline-block;
    padding-top: 1px;
    padding-bottom: 2px;
    cursor: pointer;
    color: white;
    zoom: 1;
    *display: inline;
  }

  .goog-te-menu-value span:nth-child(3) {
    display:none;
  }

  .goog-te-gadget-simple img:nth-child(1) {
    background-position: 19px 0px !important;
  }

  .goog-te-banner-frame.skiptranslate {display: none!important;}
  body {top: 0px!important;}

  .submit-request {
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 15px;
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .nav-wrapper {
    padding-block-end: 0px;
  }
  /* google translator poweredby text hide */
  #google_translate_element_mobile {
    border: solid white 1px;
    border-radius: 4px;
    /* background-color: #004071; */
    overflow: hidden !important;
    height: 30px !important;
    padding: 0px !important;
    padding-left: 15px !important;
  }
  #google_translate_element a {
    display: none;
  }

  div.goog-te-gadget {
    color: transparent !important;
  }

  /* google translator bar hide */
  .goog-te-banner-frame.skiptranslate {
    display: none !important;
  }
  .goog-te-gadget .goog-te-combo {
    color: rgb(255, 255, 255) !important;
    border-radius: 4px;
  }
  .goog-te-combo option {
    color: #333 !important;
  }
  .goog-logo-link {
    display:none !important;
  }

  .goog-te-gadget {
    color: transparent !important;
  }

  .goog-te-gadget .goog-te-combo {
    color: rgb(255, 255, 255) !important;
  }
  .google-translate-button {
    padding: 7px;
    padding-right: 10px;
  }

  .goog-te-banner-frame.skiptranslate {display: none!important;}
  body {top: 0px!important;}

  .error-message {
    margin-block-start: 1px !important; 
  }

  .hero {
    padding: 35px;
  }

  .hero-wrapper {
    width: 100%;
  }

  .hero-search {
    padding: 10px 24px 10px 44px;
  }

  .request-field-dropdown {
    left: 20px;
    top: 40px;
  }
}

.breadcrumb-color {
  color: #253675 !important;
}

.breadcrumb-color * {
  color: #253675 !important;
}

.body-height {
  height: 80%;
}

a.breadcrumbs-link>font>font {
  color: #253675 !important;
}
